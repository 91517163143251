.container {
  display: flex;
  flex-shrink: 1;
  align-items: center;
}

.starContainer {
  flex: 1;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.star {
  max-width: 75px;
  max-height: 75px;
  transition: all 0.3s;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  fill: #e4e5e9; /* Default color for unselected star */

  &.selected {
    fill: var(--color); /* Use CSS variable for custom color */
    scale: 1.1;
  }
}

.starNumber {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #aaa;
  font-size: 20px;

  @media (max-width: 767.98px) {
    position: static;
    font-size: 16px;
    text-align: center;
    transform: none;
    display: block;
  }
}

.emotion {
  flex-grow: 1;
  border-top: 6px solid gray;
  text-align: center;
  padding-top: 0.5em;
  font-size: 28px;

  @media (max-width: 767.98px) {
    font-size: 18px;
  }

  &.negative {
    border-top-color: #bc1826;
    color: #bc1826;
    flex: 7;
  }

  &.neutral {
    border-top-color: #f9ab06;
    color: #f9ab06;
    flex: 2;
  }

  &.positive {
    border-top-color: #31af00;
    color: #31af00;
    flex: 2;
  }
}


